import React, { useEffect } from 'react'
import Header from '../components/Header'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import UserList from './UserList'
import OrgForm from './OrgForm'

export default function SuperAdmin() {

  const user = useSelector(state => state?.user?.user)
  const navigate = useNavigate()


  useEffect(()=>{
      if(user?.role !== "SuperAdmin"){
          navigate("/")
      }
  },[user])
  
  return (
    <>
     <div>
       <Header/>
      </div>   
      <div className='text-5xl p-3 text-black mt-20 flex items-center justify-between px-10'>
        <div className='text-xl text-black  w-96 text-center'>Welocome to Super Admin Dashboard</div>
        <div className='flex gap-1'>
        <div className='bg-green-600 font-semibold text-xs text-white p-2 rounded'>
          <Link to="/ApprovedUsersList">Approved User</Link>
        </div>
        <div className='bg-green-600 font-semibold text-xs text-white p-2 rounded'>
          <Link to="/superAdmin/OrgDashboard">Org Dashboard</Link>
        </div>
        </div>
      </div>
        <UserList/>
    </>
  )
}
