import React from 'react'
import Header from '../components/Header'
import HeroSection from '../components/HeroSection'
import FunctionalSec from '../components/FunctionalSec'
import ProductsSection from '../components/ProductsSection'
import ReviewSection from '../components/ReviewSection'
import FAQSection from '../components/FAQSection'
import Footer from '../components/Footer'
import ResponsiveFooter from '../components/ResponsiveFooter'

export default function Home() {
  return (
    <>
      <div>
        <Header />
      </div>
      <div className=' mt-20 flex items-center justify-center'>
      </div>
      <HeroSection />
      <FunctionalSec />
      <ProductsSection/>
      <ReviewSection/>
      <FAQSection/>
      <ResponsiveFooter/>
    </>

  )
}
